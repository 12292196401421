import { tv } from 'tailwind-variants'

export const bodySize = tv({
	base: 'm-auto max-w-[90rem] px-6 md:px-20',
	variants: {
		fullSize: { true: 'm-auto w-full max-w-none px-0 md:px-0' },
		color: {
			green: 'bg-green-500',
			white: 'bg-white',
			orange: 'bg-orange-600',
		},
	},
})
